.sidebar {
  background: #1f2125;

  height: 100vh;
  border-right: 0.7px solid #d8d8d87d;

  animation: fadeIn 0.7s ease-in-out forwards;
  opacity: 1;
  header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1f2125;

    img {
      // width: 100px;
      margin: auto;
      transition: 0.3s;
    }
  }

  .sidebar-menu {
    height: calc(100vh - 150px);
    // overflow: auto;
    background: #1f2125 !important;

    &::-webkit-scrollbar {
      width: 1px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
    }
  }

  li {
    color: rgb(108, 107, 107);
  }

  svg {
    width: 20px;
  }
}

.routes {
  height: 95%;
  overflow: auto;
}

.version {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
