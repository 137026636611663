.cast-form {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    .row {
      display: flex;
      justify-content: space-between;
      gap: 15px;
    }
  }
}

.info-drawer {
  .container {
    width: 100%;

    img {
      width: 220px;
      height: 270px;
      object-fit: cover;
      float: left;
    }
    .info {
      font-size: 22px;
      display: inline;
    }
  }

  .galeries {
    width: 100%;
    margin-top: 30px;
    text-align: start;
    display: flex;

    flex-direction: column;
    align-items: flex-start;

    p {
      font-size: 18px;
    }
    .list {
      margin-top: 5px;

      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .galery {
        img {
          width: 100px;
          height: 120px;
          object-fit: cover;
        }
      }
    }
  }
}

.galeries {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.galery-upload {
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgba(225, 225, 225, 0.522);
  padding: 20px;
  border-radius: 10px;
}

.galery {
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgba(225, 225, 225, 0.522);
  padding: 20px;
  border-radius: 10px;

  .image {
    width: 150px;
    height: 160px;

    object-fit: cover;
    border-radius: 5px;
  }
}

.cast {
  display: flex;
  gap: 10px;

  img {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    object-fit: cover;
  }

  .info {
    color: gray;
    margin-right: 5px;
  }
}
