.more-apps-page {
  width: 100%;

  .list {
    margin-top: 20px;

    width: 100%;

    display: grid;
    gap: 20px;

    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .block {
      // width: 200px;
      height: 200px;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid rgba(164, 164, 164, 0.605);
      border-radius: 10px;

      .icon {
        width: 100%;
        height: 100px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background-color: #18191c;
        margin-bottom: 10px;

        img {
          width: 150px;
          height: 80px;
        }
      }

      p {
        margin-bottom: 10px;
      }
    }
  }
}
