.welcome-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    max-width: 1200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 25px;
      font-weight: bold;
      color: rgba(255, 189, 18, 1);
      margin-bottom: 10px;
    }

    .description {
      font-size: 18px;
      color: rgba(164, 164, 164, 1);
      margin-bottom: 10px;
      width: 600px;
    }

    .steps-list {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 500px;
      .step {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(31, 33, 37, 1);
        padding: 15px 20px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        box-shadow: 3px 2px 9px 1px rgb(31 33 37 / 49%);

        background-color: #1f2125;
        background-image: linear-gradient(62deg, #1f2125 0%, #414245 100%);

        &:hover {
          transform: scale(1.05);
        }
        span {
          color: rgba(255, 189, 18, 1);
        }

        p {
          color: white;
        }

        .button {
          svg {
            width: 30px;
            height: 30px;
            fill: rgba(24, 119, 242, 1);
          }
        }
      }
    }
  }
}
