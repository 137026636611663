.enable-button {
  background-color: #458bd5;

  &:hover {
    background-color: #458bd5 !important;
  }
}

.disable-button {
  background-color: #f07167;

  &:hover {
    background-color: #f07167 !important;
  }
}

.protect-button {
  color: white;
  background-color: #0c7d9a;

  &:hover {
    background-color: #096880 !important;
  }
}

.unprotect-button {
  background-color: #ce4257;

  &:hover {
    background-color: #c55f6e !important;
  }
}

.enable-button {
  background-color: #0081a7;

  &:hover {
    background-color: #03a0d0 !important;
  }
}

.disable-button {
  color: white;
  background-color: #ef798a;

  &:hover {
    background-color: #f68899 !important;
  }
}

.delete-button {
  background-color: #dc3545;

  &:hover {
    background-color: #c42837 !important;
  }
}

.fetch-button {
  width: 100px;
  background-color: #35b2dc;

  &:hover {
    background-color: #35b2dc !important;
  }
}
