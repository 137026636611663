@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

$active-color: var(--main-active-color);

.ant-btn-primary {
  background-color: var(--main-active-color);

  &:hover {
    opacity: 0.7;
    background-color: var(--main-active-color) !important;
  }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main-active-color) !important;
}

.ant-tabs-ink-bar {
  background-color: var(--main-active-color) !important;
}

.ant-switch.ant-switch-checked {
  background-color: var(--main-active-color) !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--main-active-color) !important;
  background-color: var(--main-active-color) !important;
}

.ant-typography-copy {
  color: var(--main-active-color) !important;
}

.ant-layout {
  background-color: #fbfdff;
}

.ant-layout-header {
  padding-inline: 40px;
}

.ant-input:focus,
.ant-input:hover {
  border-color: var(--main-active-color) !important;
}

.ant-menu-item-selected {
  background-color: green;
  color: blue;
}

.antd-sidebar-menu {
  background-color: #1f2125;

  .ant-menu-submenu-title,
  .ant-menu-item {
    width: 100%;
    margin: 0;
    border-radius: 0;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;

    color: rgb(180, 180, 180) !important;

    &:hover {
      color: white !important;
    }
  }

  .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: var(--main-active-color) !important;
    opacity: 0.8;
  }

  .ant-menu-item-selected {
    color: white !important;
    background-color: var(--main-active-color) !important;
  }
}

.ant-pagination-item a {
  display: inline !important;
}

.ant-table-pagination {
  gap: 10px;
}

.ant-btn-dangerous {
  &:hover {
    background-color: #f96565 !important;
  }
}

.ant-btn-dangerous {
  &:hover {
    color: white !important;
  }
}

.red-row {
  background-color: #ffccc7 !important;

  &:hover {
    background-color: #f9656564 !important;
  }
}

.table-green-row {
  background-color: #9dff9444 !important;
}

.clickable-row {
  cursor: pointer;
}

.grid-input {
  display: grid;
  grid-gap: 0px 30px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

.ant-upload {
  width: 100% !important;
}

.ant-upload-list-item-name {
  max-width: 400px !important;
}

.ant-btn {
  &:hover {
    transform: scale(1.02);

  }
}