.header {
  background-color: #fbfdff !important;
  position: fixed;
  width: 100%;
  top: 0;
  transition: 0.5s;
  z-index: 10;

  animation: fadeIn 0.7s ease-in-out forwards;
  opacity: 1;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  hr {
    margin: 0;
    background-color: rgba(128, 128, 128, 0.174);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
