.list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.icon-upload {
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgba(225, 225, 225, 0.522);
  padding: 20px;
  border-radius: 10px;
}

.gallery-item {
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgba(225, 225, 225, 0.522);
  padding: 20px;
  border-radius: 10px;

  .image {
    width: 150px;
    height: 160px;

    object-fit: cover;
    border-radius: 5px;
  }
}
