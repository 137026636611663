.page {
  width: 100%;
  height: 100vh;
  background-color: rgba(22, 24, 27, 1);

  background-image: url(../../img/login-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .body {
    max-width: 1200px;
    display: flex;
    gap: 50px;
  }

  .container {
    width: 500px;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    .form-block {
      width: 330px;
      padding: 80px 0 0 30px;
    }

    .title {
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 0.2px;
      margin-bottom: 50px;
      color: var(--main-active-color);
    }

    .error {
      margin-top: 10px;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer {
      margin-top: 40px;
      text-align: end;

      a {
        color: var(--main-active-color);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .right-block {
      width: 80px;
      height: 100%;
      background-color: rgba(240, 247, 253);
    }
  }

  .title {
    width: 450px;
    font-size: 45px;
    color: white;

    span {
      color: var(--main-active-color);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .page {
    background-color: rgba(22, 24, 27, 1);
    background-image: none;
    height: 120vh;
    overflow: auto;

    .body {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .container {
      width: 300px;
      padding: 30px;
      .form-block {
        width: 100%;
        padding: 0px;
      }

      .right-block {
        display: none;
      }
    }

    .title {
      width: 300px;
      font-size: 20px;

      span {
        font-weight: bold;
        color: var(--main-active-color);
      }
    }
  }
}
