.profile-form {
  margin-top: 20px;
  width: 610px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  .image-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .input-fields {
    width: 400px;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

.notifications-tab {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .notification {
    display: flex;
    p {
      width: 200px;
    }
  }
}
