.profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  min-width: 140px;
  padding: 0px 10px;
  transition: 0.3s;
  &:hover {
    background-color: rgba(215, 215, 215, 0.195);
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
