.menu {
    min-width: 130px;

    .item {
        padding: 2px 5px !important;

        svg {
            font-size: 15px;
        }

        span {
            color: #00000086;
        }
        .row {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 5px;
        }
    }
}
