.go-live-container {
  // width: 850px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;

  .info-block {
    width: 310px;

    .images-block {
      display: flex;
      justify-content: space-between;
    }
  }

  .video-block {
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    video {
      border-radius: 10px;
    }
  }
}

.live-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .live-button {
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 0 0 0 #f96565;
    transform: scale(1);
    animation: pulse 2s infinite;
    svg {
      width: 30px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #f584847a;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
