.container {
  width: 400px;
  .tab {
    display: grid;
    grid-gap: 0px 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .description {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  .images-list {
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    gap: 0px 30px;
    margin-bottom: 20px;
  }
}
