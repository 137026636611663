.body {
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  // gap: 10px;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  max-width: 1200px;
}
// .body {
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   flex-wrap: wrap;

//   .box {
//     width: 49%;
//     display: flex;
//     flex-direction: column;
//     gap: 20px;
//   }
// }

.row {
  display: flex;
  gap: 20px;
}

.images-section {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.monitization-restriction {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.container {
  width: 100%;
  min-width: 400px;
  background-color: rgba(214, 214, 214, 0.161);
  padding: 20px;
}
