.modal-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 111;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;

  img {
    width: 200px;
    margin-bottom: 30px;
  }

  p {
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
}
