.tab {
  width: 400px;

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .description {
    font-size: 15px;
    margin-bottom: 10px;
  }
}
