.page {
  width: 100%;
  height: 100%;

  animation: fadeIn 0.5s ease-in-out;
  opacity: 1;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 5px;

    .back-button {
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #9c9c9c;
      color: #9c9c9c;
      transition: 0.2s;

      &:hover {
        cursor: pointer;
        background-color: #9c9c9c;
        color: white;
      }
    }

    .routes {
      font-size: 15px;
      span {
        color: rgb(156, 156, 156);
      }

      .active {
        font-weight: 400;
        color: rgb(34, 34, 34);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
